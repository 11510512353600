
import Cookies from 'js-cookie';
import { formatListMessages, parseJson } from './HtmlHelper';

const buildJsonOptions = (method, params) => {
  return {
    method: method, // 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'include', //'same-origin', // include, *same-origin, omit
    crossDomain: true,
    headers: params instanceof FormData ?
      {
        //'Content-Type': 'multipart/form-data',
        'X-CSRF-TOKEN': Cookies.get('csrf_access_token')
      }
      : {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-TOKEN': Cookies.get('csrf_access_token')
      },
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: (params instanceof FormData) ? params : (params ? JSON.stringify(params) : null), // body data type must match "Content-Type" header
  }
}

let _apiUrl = "";
const getApiUrl = async () => {
  if (_apiUrl === "") {
    const res = await fetch("/app.json?v=0.11");
    const json = await res.json()
    _apiUrl = json.apiUrl;
  }
  return _apiUrl;
}

const mapUrl = async (endpoint) => {
  if (endpoint.startsWith("http")) return endpoint
  const apiUrl = await getApiUrl();
  const url = apiUrl + (apiUrl.endsWith("/") ? "" : "/") + endpoint;
  return url;
}

const getParamStr = (params) => {
  if (!params) return ""
  const ret = []
  for (var p in params) {
    ret.push(`${p}=${params[p]}`)
  }
  return ret.join("&")
}

const fetchx = async (method, endpoint, params = null, skipRedirectLogin = false) => {
  const options = buildJsonOptions(method, params)
  try {
    let fullUrl = await mapUrl(endpoint);
    if (method == "GET" && params)
      fullUrl += (fullUrl.includes("?") ? "&" : "?") + getParamStr(params)

    const response = await fetch(fullUrl, options);
    if (response.ok) {
      let data = {};
      try {
        data = await response.json(); // this would raise error i.e. Delete method
      } catch { }
      return { data: data, ok: response.ok, code: response.status }
    }
    const msg = await checkResponseAsync(response, () => {
      return fetchx(endpoint, options);
    }, skipRedirectLogin);
    return { data: msg == "" ? response.statusText : msg, ok: false, code: response.status }

  } catch (ex) {
    console.log(ex)
    return errorCatcher(ex);
  }
}

const errorCatcher = (ex) => {
  console.log(ex)
  let errMsg = ex.message;
  if (errMsg == "Failed to fetch")
    errMsg = `Could not connect to API server. Please try again later.`
  return {
    data: errMsg, ok: false, code: 500
  }
}

const getBlob = async (endpoint) => {
  const options = buildJsonOptions("GET", null);
  try {
    const url = await mapUrl(endpoint)
    const response = await fetch(url, options);
    if (response.ok) {
      const data = await response.blob();
      return { data: data, ok: response.ok, code: response.status }
    }
    const msg = await checkResponseAsync(response, () => {
      return getBlob(endpoint);
    });
    return { data: msg, ok: false, code: response.status }
  } catch (ex) {
    return errorCatcher(ex)
  }
}

const get = async (endpoint, params = null) => {
  return fetchx('GET', endpoint, params)
}

const tryGet = async (endpoint, params = null) => {
  return await fetchx("GET", endpoint, params, true)
}

const post = async (endpoint, params) => {
  return fetchx('POST', endpoint, params)
}

const put = async (endpoint, params) => {
  return fetchx('PUT', endpoint, params)
}

const postOrPut = async (endpoint, editingId, params) => {
  if (editingId) {
    const url = endpoint + (endpoint.endsWith("/") ? editingId : "/" + editingId)
    return put(url, params)
  }
  return post(endpoint, params)
}

const deletex = async (endpoint) => {
  return fetchx('DELETE', endpoint)
}

const checkResponseAsync = async (response, onAuthRefreshed, skipRedirectLogin = false) => {
  let msg = await response.text();

  if (msg.includes("Signature has expired") || msg.includes("Session has expired")) {
    const refreshRes = await post("auth/refresh", {});
    if (refreshRes.ok) {
      console.log("Token refreshed.")
      if (onAuthRefreshed) {
        onAuthRefreshed();
        return ""
      }
    }
  }

  if (response.status === 401 && skipRedirectLogin == false) {
    localStorage.clear();
    window.location.href = "/account/login";
  }

  try {
    const err = parseJson(msg)
    if (err) msg = stringifyError(err)
  } finally {
    return msg;
  }

}

const stringifyError = (err) => {
  let msg = "";
  if (err?.errors) { //&& errJson.type?.includes("https://tools.ietf.org/html/")) {
    msg = formatListMessages(`${err.status}: ${err.title}`, err.errors)
  } else if (err?.message)
    msg = err.message;
  else
    msg = JSON.stringify(err);
  return msg;
}

const apix = {
  get,
  post,
  put,
  postOrPut,
  deletex,
  getBlob,
  tryGet,
  getApiUrl,
}
export default apix;