// import axios from 'axios';
import apix from './Apix';
import alertify from 'alertifyjs';
import IntlMessages from './IntlMessages';
import { useIntl, FormattedMessage } from 'react-intl';
import AppLocale from 'lang';
import { decode } from 'urlsafe-base64';

const expirationMsgs = ["Signature has expired", "Session has expired"];
// const axiosApiInstance = axios.create();

const logoutUser = () => {
  localStorage.clear();
  window.location.href = "/account/logout";
}

const refreshToken = async (originalRequest) => {
  const refreshRes = await apix.post("auth/refresh", {});
  if (refreshRes.ok) {
    // return axiosApiInstance(originalRequest);
  }
  // else {
  //   logoutUser();
  // }

}

function decodeHtml(html) {
  html = html.replace("\n", "<br>").replace("\r", "<br><br>")
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return decodeURI(txt.value);
}

export const displayErrorDialog = (error) => {
  console.log(error)
  const Errors = `<div className="text-danger">${decodeHtml(error.message)}</div>`;
  alertify.alert(Errors).set({ title: `${error.response.statusText} (${error.response.status})`, label: "OK" });
}


export const showSuccessMessage = (method, msg) => {

  method = method.toLowerCase();

  const currentLang = localStorage.getItem("currentLanguage") || 'en';

  const messages = AppLocale[currentLang]["messages"];

  const getMessage = (id) => {
    if (messages[id]) {
      return messages[id];
    }
    return id;
  }

  if (msg && ["post", "put"].indexOf(method) > -1) {
    return alertify.success(getMessage(msg));
  }


  if (method == 'post') {
    alertify.success(getMessage('crud_msg.succes_save'));
  }

  if (method == 'put') {
    alertify.success(getMessage('crud_msg.succes_save'));
  }
}

