
module.exports = {
  'select_language': 'Select Language',
  'administration': 'Administration',
  'design': 'Design',
  'models': 'Models',
  'pages' : 'Pages',
  'workflows': 'Workflows',
  'security': 'Security',
  'users': 'Users',
  'roles': 'Roles',
  'phone_number': "Phone Number",
  
  'save_draft': 'Save Draft',
  'update_draft': 'Update Draft',
  'total_ot': 'Total OT',
  'set_as_default_workflow_of': 'Set as default workflow of',
  'not_found_file' : 'No file found',
  'total_fields': 'Total fields'
};

